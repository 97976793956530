<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="6" sm="12">
        <v-card>
          <v-card-title>
            {{ $t('statistics.numberOfAcceptedDrivings') }}
          </v-card-title>
          <v-card-text>
            <pie-chart
              :backgroundColors="backgroundColors"
              :data="driverAcceptData"
              :labels="driverAcceptLabels"></pie-chart>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="mb-5" cols="auto" md="6" sm="12">
        <v-card>
          <v-card-title>
            {{ $t('statistics.numberOfRejectedDrivings') }}
          </v-card-title>
          <v-card-text>
            <pie-chart
              :backgroundColors="backgroundColors"
              :data="driverRejectData"
              :labels="driverRejectLabels"></pie-chart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mb-5" cols="auto" md="6" sm="12">
        <v-card>
          <v-card-title>
            {{ $t('statistics.vehicleDrivingsNumber') }}
          </v-card-title>
          <v-card-text>
            <bar-chart
              :text="$i18n.t('statistics.vehicleDrivingsNumber')"
              :backgroundColors="backgroundColors"
              :data="vehicleActivityData"
              :labels="vehicleActivityLabels"></bar-chart>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="mb-5" cols="auto" md="6" sm="12">
        <v-card>
          <v-card-title>
            {{ $t('statistics.vehicleProfit') }}
          </v-card-title>
          <v-card-text>
            <bar-chart
              :text="$i18n.t('statistics.vehicleProfit')"
              :backgroundColors="backgroundColors"
              :data="revenuePerVehicleData"
              :labels="revenuePerVehicleLabels"></bar-chart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mb-5" cols="auto" md="6" sm="12">
        <v-card>
          <v-card-title>
            {{ $t('statistics.monthDrivingsNumber') }}
          </v-card-title>
          <v-card-text>
            <pie-chart
              :backgroundColors="backgroundColors"
              :data="numberOfDrivingsData"
              :labels="numberOfDrivingsLabels"></pie-chart>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="mb-5" cols="auto" md="6" sm="12">
        <v-card>
          <v-card-title>
            {{ $t('statistics.driverProfit') }}
          </v-card-title>
          <v-card-text>
            <pie-chart
              :backgroundColors="backgroundColors"
              :data="driverPercentsData"
              :labels="driverPercentsLabels"></pie-chart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { months } from '@/mixins/default-values';
import PieChart from '@/components/PieChart';
import BarChart from '@/components/BarChart';
import { colors } from '@/mixins/background-colors';

export default {
  name: 'GeneralReportPage',
  components: {
    PieChart,
    BarChart,
  },
  props: ['filterOption'],
  data: () => ({
    backgroundColors: [],
    vehicleActivityLabels: [],
    vehicleActivityData: [],
    driverAcceptLabels: [],
    driverAcceptData: [],
    driverRejectLabels: [],
    driverRejectData: [],
    revenuePerVehicleData: [],
    revenuePerVehicleLabels: [],
    numberOfDrivingsData: [],
    numberOfDrivingsLabels: [],
    driverPercentsData: [],
    driverPercentsLabels: [],
    backendUrl: process.env.VUE_APP_API_URL,
  }),
  created() {
    this.backgroundColors = colors;
    this.applyFilter();
  },
  computed: {
    months() {
      return months(i18n);
    },
  },
  methods: {
    applyFilter() {
      this.loadGeneralReportData();
    },
    async loadGeneralReportData() {
      await this.$store.dispatch('statistic/getReportGeneralData', { type: this.filterOption }).then((res) => {
        this.loadDriverAcceptActivity(res.data.acceptedDrivingOfDrivers);
        this.loadDriverRejectActivity(res.data.rejectedDrivingOfDrivers);
        this.loadNumberOfDrivings(res.data.drivingCountByMonth);
        this.loadVehicleActivity(res.data.vehicleActivity);
        this.loadRevenuePerVehicle(res.data.vehicleRevenue);
        this.loadCommissionPerDriver(res.data.driversCommission);
      });
    },
    loadVehicleActivity(data) {
      this.vehicleActivityLabels = [];
      this.vehicleActivityData = [];

      data.forEach((el) => {
        let brand = el.vehicle?.brand;
        let plateNumber = el.vehicle?.plate_number;
        if (brand) {
          brand += ' ';
        }
        this.vehicleActivityLabels.push(brand + plateNumber || 'N/A');
        this.vehicleActivityData.push(el.total);
      });
    },
    loadDriverAcceptActivity(data) {
      this.driverAcceptLabels = [];
      this.driverAcceptData = [];

      data.forEach((el) => {
        let firstName = el.user?.profile?.first_name;
        let lastName = el.user?.profile?.last_name;
        if (firstName) {
          firstName += ' ';
        }
        this.driverAcceptLabels.push(firstName + lastName || 'N/A');
        this.driverAcceptData.push(el.total);
      });
    },
    loadDriverRejectActivity(data) {
      this.driverRejectLabels = [];
      this.driverRejectData = [];

      data.forEach((el) => {
        let firstName = el.user?.profile?.first_name;
        let lastName = el.user?.profile?.last_name;
        if (firstName) {
          firstName += ' ';
        }
        this.driverRejectLabels.push(firstName + lastName || 'N/A');
        this.driverRejectData.push(el.total);
      });
    },
    loadNumberOfDrivings(data) {
      this.numberOfDrivingsLabels = [];
      this.numberOfDrivingsData = [];

      data.forEach((el) => {
        this.numberOfDrivingsLabels.push(this.months[parseInt(el.month) - 1].name);
        this.numberOfDrivingsData.push(el.total);
      });
    },
    loadRevenuePerVehicle(data) {
      this.revenuePerVehicleLabels = [];
      this.revenuePerVehicleData = [];

      data.forEach((el) => {
        let brand = el.vehicle?.brand;
        let plateNumber = el.vehicle?.plate_number;
        if (brand) {
          brand += ' ';
        }
        this.revenuePerVehicleLabels.push(brand + plateNumber || 'N/A');
        this.revenuePerVehicleData.push(parseFloat(el.total_price).toFixed(2));
      });
    },
    loadCommissionPerDriver(data) {
      this.driverPercentsLabels = [];
      this.driverPercentsData = [];

      data.forEach((el) => {
        let firstName = el.user_data?.first_name;
        let lastName = el.user_data?.last_name;
        if (firstName) {
          firstName += ' ';
        }
        this.driverPercentsLabels.push(firstName + lastName || 'N/A');
        this.driverPercentsData.push(parseFloat(el.total_commission).toFixed(2));
      });
    },
  },
  watch: {
    filterOption: {
      handler() {
        this.applyFilter();
      },
    },
  },
};
</script>
