<template>
  <div class="cost-effectiveness-page">
    <v-data-table
      :items-per-page="50"
      :footer-props="{
        pageText: `{0}-{1} ${$t('tables.of')} {2}`,
        itemsPerPageText: $t('tables.reportsByPage'),
        itemsPerPageOptions: [5, 10, 20, 50, 100],
      }"
      :items="reportData"
      :no-data-text="$t('tables.noDataText')"
      :no-results-text="$t('tables.noDataText')"
      :headers="headers"
      class="report-table"
      :mobile-breakpoint="null">
      <template slot="header">
        <thead>
          <tr>
            <th colspan="1"></th>
            <th v-for="timeHeader in timeHeaders" :key="timeHeader" colspan="3" class="time-header-th">
              <div class="text-center" v-if="filter === 2 || filter === 3">{{ timeHeader }}</div>
              <div class="text-center" v-else>{{ $t(`months.${timeHeader}`) }}</div>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:item.client_name="{ item }">
        <span class="font-weight-bold">{{ item.client_name }} </span>
      </template>
    </v-data-table>
    <!--    <v-btn class="mb-2 float-right" color="primary"> {{ $t('buttons.downloadPDF') }} </v-btn>-->
  </div>
</template>

<script>
import i18n from '@/i18n/i18n';
import { clientsReportHeaders } from '@/mixins/data-table-headers';

export default {
  name: 'CostEffectivenessReportPage',
  data() {
    return {
      currentQuarter: '',
      currentYear: new Date().getFullYear(),
      reportData: [],
      filter: Number,
    };
  },
  props: { filterOption: Number },
  methods: {
    getQuarter() {
      let currentMonth = new Date().getMonth() + 1;
      if (1 <= currentMonth && currentMonth <= 3) {
        return 1;
      } else if (4 <= currentMonth && currentMonth <= 6) {
        return 2;
      } else if (7 <= currentMonth && currentMonth <= 9) {
        return 3;
      } else if (10 <= currentMonth && currentMonth <= 12) {
        return 4;
      } else {
        return '';
      }
    },
    getData() {
      if (this.filter === 1) {
        this.getClientsQuarterReport();
      } else if (this.filter === 2) {
        this.getClientsYearReport(this.currentYear);
      } else if (this.filter === 3) {
        this.getClientsYearReport(this.currentYear - 1);
      }
    },
    async getClientsQuarterReport() {
      await this.$store
        .dispatch('statistic/getClientsQuarterReport', { year: this.currentYear, quarter: this.currentQuarter })
        .then((res) => {
          this.reportData = Object.values(res.data.data).map((vehicle) => {
            vehicle.data = vehicle.data.map((dataItem) => {
              dataItem.cost = parseFloat(dataItem.cost).toFixed(2);
              dataItem.earnings = parseFloat(dataItem.earnings).toFixed(2);
              dataItem.profit = parseFloat(dataItem.profit).toFixed(2);
              return dataItem;
            });

            return vehicle;
          });
        });
    },

    async getClientsYearReport(year) {
      await this.$store.dispatch('statistic/getClientsYearReport', { year: year }).then((res) => {
        this.reportData = Object.values(res.data.data).map((vehicle) => {
          vehicle.data = vehicle.data.map((dataItem) => {
            dataItem.cost = parseFloat(dataItem.cost).toFixed(2);
            dataItem.earnings = parseFloat(dataItem.earnings).toFixed(2);
            dataItem.profit = parseFloat(dataItem.profit).toFixed(2);
            return dataItem;
          });

          return vehicle;
        });
      });
    },
  },

  created() {
    this.currentQuarter = this.getQuarter();
    this.filter = this.filterOption;
    this.getData();
  },

  computed: {
    headers() {
      return clientsReportHeaders(i18n, this.filter);
    },

    timeHeaders() {
      if (this.reportData.length > 0) {
        return this.reportData[0].data.map((vehicle) => vehicle.period);
      } else {
        return [];
      }
    },
  },

  watch: {
    filterOption(newFilterOption) {
      this.filter = newFilterOption;
      this.getData();
    },
  },
};
</script>

<style scoped>
.report-table {
  width: 100%;
}

.cost-effectiveness-page >>> .v-data-table-header-mobile {
  display: none;
}
.time-header-th {
  background-color: #dee5f8;
}
</style>
