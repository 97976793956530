export function months(i18n) {
  return [
    { id: 1, name: i18n.t('months.jan') },
    { id: 2, name: i18n.t('months.feb') },
    { id: 3, name: i18n.t('months.mar') },
    { id: 4, name: i18n.t('months.apr') },
    { id: 5, name: i18n.t('months.may') },
    { id: 6, name: i18n.t('months.jun') },
    { id: 7, name: i18n.t('months.jul') },
    { id: 8, name: i18n.t('months.aug') },
    { id: 9, name: i18n.t('months.sep') },
    { id: 10, name: i18n.t('months.oct') },
    { id: 11, name: i18n.t('months.nov') },
    { id: 12, name: i18n.t('months.dec') },
  ];
}

export function filterOptions(i18n) {
  return [
    { name: i18n.t('filters.currentYear'), value: 1 },
    { name: i18n.t('filters.lastYear'), value: 2 },
    { name: i18n.t('filters.lastThreeYears'), value: 3 },
  ];
}
