export const colors = [
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
];
