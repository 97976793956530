<script>
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  props: {
    text: {
      type: String,
    },
    title: {
      type: String,
      default: '',
    },
    backgroundColors: {
      type: Array,
    },
    labels: {
      type: Array,
    },
    data: {
      type: Array,
    },
  },
  mounted() {
    this.renderPieChart();
  },
  methods: {
    renderPieChart: function () {
      this.renderChart(
        {
          labels: this.labels,
          datasets: [
            {
              label: this.text,
              backgroundColor: this.backgroundColors,
              data: this.data,
            },
          ],
        },
        { responsive: true, maintainAspectRatio: false }
      );
    },
  },
  watch: {
    data: function () {
      this.renderPieChart();
    },
  },
};
</script>
