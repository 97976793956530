<template>
  <div>
    <div class="report-type-and-time">
      <div>
        <div class="report-type-label">{{ $t('statisticsPage.reportType') }}</div>
        <v-chip-group class="report-chips" id="statistics-page-step-0">
          <v-chip v-for="chip in chips" :key="chip.value" @change="changeChip(chip)">
            <span :class="{ 'blue-text': selectedChip === chip.value }">{{ $t(`statisticsPage.${chip.name}`) }}</span>
          </v-chip>
        </v-chip-group>
      </div>
      <div class="report-time">
        <v-autocomplete
          v-if="selectedChip !== 1"
          v-model="filterOptionTwo"
          :items="filterOptionsTwo"
          item-text="name"
          item-value="value"
          :label="$t('statisticsPage.reportTime')"
          required
          @change="applyFilter"
          class="mb-0"
          :no-data-text="$t('select.noDataAvailable')"
          @focus="$event.target.click()">
        </v-autocomplete>
        <div v-else>
          <v-row>
            <v-spacer></v-spacer>
            <v-col align-self="center" xl="6" lg="6" md="6" sm="6">
              <v-btn
                target="_blank"
                class="mb-2 float-right"
                color="primary"
                @click="downloadPDF"
                id="statistics-page-step-2">
                {{ $t('buttons.downloadPDF') }}
              </v-btn>
            </v-col>
            <v-col align-self="center" xl="6" lg="6" md="6" sm="6">
              <v-autocomplete
                id="statistics-page-step-1"
                v-model="filterOption"
                :items="filterOptions"
                item-text="name"
                item-value="value"
                :label="$t('filters.filter')"
                required
                @change="applyFilter"
                class="mb-0"
                :no-data-text="$t('select.noDataAvailable')"
                @focus="$event.target.click()">
              </v-autocomplete>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div class="report-content">
      <general-report-page
        v-if="selectedChip === 1"
        :key="$store.state.language.currentLanguage"
        :filter-option="filterOption"></general-report-page>
      <cost-effectiveness-report-page
        v-if="selectedChip === 2"
        :filterOption="filterOptionTwo"></cost-effectiveness-report-page>
      <utilization-report-page v-if="selectedChip === 3" :filterOption="filterOptionTwo"></utilization-report-page>
      <clients-report-page v-if="selectedChip === 4" :filterOption="filterOptionTwo"></clients-report-page>
    </div>

    <div class="tourStyle">
      <v-tour name="statisticsTour" :steps="stepsTour" :callbacks="myCallbacks" :options="options"></v-tour>
    </div>
  </div>
</template>

<script>
import i18n from '@/i18n/i18n';
import GeneralReportPage from '@/views/pages/statistics/GeneralReportPage.vue';
import CostEffectivenessReportPage from '@/views/pages/statistics/CostEffectivenessReportPage.vue';
import UtilizationReportPage from '@/views/pages/statistics/UtilizationReportPage.vue';
import ClientsReportPage from '@/views/pages/statistics/ClientsReportPage.vue';
import { filterOptions } from '@/mixins/default-values';
import { getAllTourSteps, statisticsPageSteps } from '@/mixins/onboarding-tutorial-steps';

export default {
  components: { GeneralReportPage, CostEffectivenessReportPage, UtilizationReportPage, ClientsReportPage },
  data() {
    return {
      filterOption: 1,
      filterOptionTwo: 1,
      selectedChip: 1,
      chips: [
        { name: 'generalReport', value: 1 },
        { name: 'costEffectiveness', value: 2 },
        { name: 'vehicleUtilization', value: 3 },
        { name: 'clientsReport', value: 4 },
      ],
      steps: [],
      visitedStepsArray: [],
      stepsTour: [],
      myCallbacks: {
        onStop: this.onComplete,
        onNextStep: this.onNextStep,
        onSkip: this.onSkip,
      },
      options: {
        labels: {
          buttonSkip: i18n.t('buttons.skip'),
          buttonPrevious: '<< ' + i18n.t('buttons.prev'),
          buttonNext: i18n.t('buttons.next') + ' >>',
          buttonStop: i18n.t('buttons.finish'),
        },
        highlight: true,
      },
    };
  },
  methods: {
    applyFilter() {
      this.$emit('filter-change', this.filterOptionTwo);
    },
    changeChip(chip) {
      this.selectedChip = chip.value;
    },
    downloadPDF() {
      this.$store
        .dispatch('statistic/downloadGeneralDataPDF', { type: this.filterOption })
        .then((res) => {
          const _url = window.URL.createObjectURL(res);
          window.open(_url, '_blank');
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async setStepsAndStartTour() {
      await this.setSteps();
      if (this.stepsTour?.length > 0) {
        this.startTour();
      }
    },
    startTour() {
      this.$tours['statisticsTour'].start();
      this.$store.dispatch('onboardingTutorial/toggleTour', true);
    },
    setSteps() {
      return new Promise((resolve) => {
        const visitedStepsParsed = JSON.parse(localStorage.getItem('LimoExpressUser'))?.user_tour_logs;

        if (visitedStepsParsed) {
          this.visitedStepsArray = visitedStepsParsed
            .filter((item) => item.component_id === '3')
            ?.map((item) => item.step_id);
          this.steps = statisticsPageSteps();
          if (this.visitedStepsArray?.length > 0) {
            this.stepsTour = this.steps.filter((step) => !this.visitedStepsArray.includes(step.target));
          } else {
            this.stepsTour = this.steps;
          }
        } else {
          this.stepsTour = [];
          let limoExpressUser = localStorage.getItem('LimoExpressUser');
          let parsedUser = limoExpressUser ? JSON.parse(limoExpressUser) : null;
          parsedUser.user_tour_logs = getAllTourSteps();

          localStorage.setItem('LimoExpressUser', JSON.stringify(parsedUser));
        }

        resolve();
      });
    },
    onComplete() {
      this.$store.dispatch('onboardingTutorial/toggleTour', false);
      this.setStepAsVisited(this.steps.pop()?.target?.slice(-1));
    },

    onSkip() {
      this.$store.dispatch('onboardingTutorial/toggleTour', false);

      const body = {
        objects: this.steps.map((item) => ({
          component_id: '3',
          step_id: item.target,
        })),
      };

      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      const lastCharsOfTarget = this.steps.map((step) => step.target.slice(-1));
      lastCharsOfTarget.forEach((step) => this.updateStorage(step));
    },

    onNextStep(currentStep) {
      const currentStepId = this.stepsTour[currentStep]?.targetId;
      this.setStepAsVisited(currentStepId);
    },

    updateStorage(currentStep) {
      const newVisitedStep = {
        component_id: '3',
        step_id: `#statistics-page-step-${currentStep}`,
      };

      let limoExpressUser = localStorage.getItem('LimoExpressUser');
      let parsedUser = limoExpressUser ? JSON.parse(limoExpressUser) : null;

      if (parsedUser) {
        if (parsedUser.user_tour_logs?.length > 0) {
          parsedUser.user_tour_logs.push(newVisitedStep);
        } else {
          parsedUser.user_tour_logs = [newVisitedStep];
        }
      }

      localStorage.setItem('LimoExpressUser', JSON.stringify(parsedUser));
    },

    setStepAsVisited(step) {
      const body = {
        objects: [
          {
            component_id: '3',
            step_id: `#statistics-page-step-${step}`,
          },
        ],
      };

      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      this.updateStorage(step);
    },
  },
  computed: {
    filterOptions() {
      return filterOptions(i18n);
    },
    filterOptionsTwo() {
      return [
        { name: i18n.t('filters.lastQuarter'), value: 1 },
        { name: i18n.t('filters.currentYear'), value: 2 },
        { name: i18n.t('filters.lastYear'), value: 3 },
      ];
    },
  },
  mounted() {
    if (this.$store.state.auth.role !== 1) {
      this.setStepsAndStartTour();
    }
  },
};
</script>

<style scoped>
.report-content {
  padding: 6vh;
  @media only screen and (max-width: 600px) {
    padding: 12px;
  }
}

.report-type-and-time {
  padding: 3vh 6vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.5);

  @media only screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    padding: 2vh;
    align-items: unset;
  }
}

.report-type-label {
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
}

.report-time {
  margin-top: 8px;
}

.blue-text {
  color: #57afee;
}

.v-chip-group .v-chip {
  display: block !important;
}

.report-chips >>> .v-slide-group__content {
  @media only screen and (max-width: 600px) {
    display: block !important;
  }
}
</style>
